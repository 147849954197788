.form-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.8rem;
  @include responsive(445px) {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  @include responsive(600px) {
    grid-column: 1 / span 2;
  }

  select {
    display: block;
    border: 1px solid $grey2;
    padding: 1rem 1rem;
    margin-top: 0.7rem;
  }
}

.form-label {
  font-weight: 500;
  color: $black;
  color: var(--rate-text);
}

.form-bottom-label {
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;

  & ion-icon {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    color: $grey8;
  }
}
.form-requirements {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}
.pass_activation {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.activate__password {
  color: #7bb20e;
}
.pass__criteria {
  color: var(--login-input);
}
.form-input-group {
  display: flex;
  height: 4.8rem;
  border-radius: 0.6rem;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ebebeb;
  background-color: var(--input-bg);

  border: 1px solid var(--login-border);

  & input {
    flex: 1;
    font-family: inherit;
    outline: none;
    min-width: 0;
    color: $black;
    padding: 0 1.2rem;
    color: var(--form-color);

    &::placeholder {
      color: $grey10;
      color: var(--form-placeholder);
    }
  }

  & button {
    background-color: #7bb20e;
    padding: 0 1.6rem;
    color: #fff;
  }

  &--1 button {
    background-color: #f1f1f1;
    color: #000;
    background-color: var(--input-num);
    color: var(--from-color);
  }
}

.form-input {
  border: 1px solid $grey9;
  height: 4.8rem;
  border-radius: 0.6rem;
  overflow: hidden;
  padding: 0 1.2rem;
  color: $black;
  font-family: inherit;
  outline: none;
  min-width: 0;
  background: #ffffff;
  background: var(--input-bg);
  border: 1px solid var(--search-border);
  color: var(--form-color);

  &--1 {
    background-color: #fff;
    border: 1px solid #ebebeb;
    background-color: var(--input-bg);

    border: 1px solid var(--login-border);
  }

  &::placeholder {
    color: $grey10;
    color: var(--form-placeholder);
  }
}

textarea.form-input {
  height: auto;
  padding: 1.2rem;
}

.input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $grey9;
  border-radius: 0.6rem;
  overflow: hidden;
  height: 4.8rem;
  padding: 0.5rem;
  // background: white;
  cursor: default;

  &--1 {
    background-color: #fff;
    border: 1px solid #ebebeb;
    background-color: var(--input-bg);

    border: 1px solid var(--login-border);
  }

  & input {
    flex: 1;
    background: transparent;
    font-family: inherit;
    outline: none;
    padding: 0 1.2rem;
    min-width: 0;
    color: $black;
    border: none;
    color: var(--login-input);

    background-color: var(--input-bg);

    &::placeholder {
      color: #bebecb;
      color: var(--form-placeholder);
    }
  }

  & button {
    align-self: stretch;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    width: 4.8rem;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: #545454;
    }
  }
}

.checkbox {
  appearance: none;
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid $grey8;
  border-radius: 0.3rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    background-color: $primary;
    color: $white;
    border: 1px solid $primary;

    &::after {
      content: "\2713";
      display: inline-block;
    }
  }
}

.checkbox__disabled {
  pointer-events: none;
}

.error__messages {
  color: $red3;
  font-size: 1.3rem;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//   -webkit-box-shadow: 0 0 0 30px white inset !important;
// }

.display__error {
  display: flex;
  align-items: center;

  .bkg__warning {
    background: $ratingsYellow;
    display: flex;
    align-items: center;
  }

  p {
    flex: 1;
  }
}

// splash screen
.splash {
  background: $white;
  padding: 0 2rem;
  height: 100%;
  text-align: center;

  img {
    margin: auto;
  }

  &__body {
    &__empty {
      background: #efefef;
      span {
        display: block;
        width: 100%;
        height: 50px;
        opacity: 0.5;
      }

      .span1 {
        opacity: 0.1;
      }

      .span2 {
        opacity: 1;
      }
    }
  }
}

.otp-medium {
  &__heading {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1.6rem;
  }

  &__blocks {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    padding-left: 24px;
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  &__block-img {
    display: block;
    width: 2.8rem;
    height: 2.8rem;
    object-fit: cover;
  }

  &__block-name {
    margin-right: auto;
  }
}

.checkbox-switch {
  appearance: none;
  display: block;
  width: 3.6rem;
  height: 2.1rem;
  background-color: #eee;
  border-radius: 10rem;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #bbb;
    border-radius: 50%;

    position: absolute;
    left: 3px;
    top: 3px;

    transition: all 0.3s;
  }

  &:checked {
    background-color: #397305;

    &::after {
      background-color: #fff;
      transform: translateX(100%);
    }
  }
}
