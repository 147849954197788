.text {
  &-primary {
    color: $primary;
  }

  &-grey {
    color: $grey3;
  }

  &-danger {
    color: #e22b23 !important;
  }

  &-white {
    color: $white;
  }

  &-cancle {
    text-decoration: line-through;
  }

  &-green {
    color: $lemon !important;
  }
}

.bold {
  font-weight: 700;
}

.contain {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 5rem;
  @include responsive-lg(1500px) {
    padding: unset;
  }
  @include responsive(830px) {
    padding: 0 3rem;
  }
  @include responsive(620px) {
    padding: 0 1.5rem;
  }
}

.hr {
  height: 1px;
  display: inline-block;
  width: 100%;
  border-top: 2px solid $grey4;
  border-top: 2px solid var(--deliver-border);
}

.tag {
  padding: 0.3rem 0.5rem;
  border-radius: 0.6rem;
  font-size: 1rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  &--green {
    background-color: $lemon;
    color: $white;
  }

  &--red {
    background-color: $discountBg;
    color: $discountTxt;
  }
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-half {
  margin-bottom: 0.6rem;
}

.mt-3 {
  margin-bottom: 3rem;
}

.p-3 {
  margin-top: 3rem;
}

.p-0 {
  padding: 0 !important;
}

.badge {
  display: inline-block;
  width: fit-content;
  border-radius: 0.5rem;

  &-danger {
    padding: 0.5rem 1rem;
    background: #a14540;
    color: white;
  }
}

.invisible {
  visibility: hidden;
}

.none {
  display: none;
}

.bg-black {
  background: black;
}

.spinner {
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 10px;

  &-black {
    div {
      background: #141414 !important;
    }
  }

  div {
    width: 8%;
    height: 24%;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 30%;
    opacity: 0;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    animation: fade458 1s linear infinite;
  }

  .bar1 {
    transform: rotate(0deg) translate(0, -130%);
    animation-delay: 0s;
  }

  .bar2 {
    transform: rotate(30deg) translate(0, -130%);
    animation-delay: -1.1s;
  }

  .bar3 {
    transform: rotate(60deg) translate(0, -130%);
    animation-delay: -1s;
  }

  .bar4 {
    transform: rotate(90deg) translate(0, -130%);
    animation-delay: -0.9s;
  }

  .bar5 {
    transform: rotate(120deg) translate(0, -130%);
    animation-delay: -0.8s;
  }

  .bar6 {
    transform: rotate(150deg) translate(0, -130%);
    animation-delay: -0.7s;
  }

  .bar7 {
    transform: rotate(180deg) translate(0, -130%);
    animation-delay: -0.6s;
  }

  .bar8 {
    transform: rotate(210deg) translate(0, -130%);
    animation-delay: -0.5s;
  }

  .bar9 {
    transform: rotate(240deg) translate(0, -130%);
    animation-delay: -0.4s;
  }

  .bar10 {
    transform: rotate(270deg) translate(0, -130%);
    animation-delay: -0.3s;
  }

  .bar11 {
    transform: rotate(300deg) translate(0, -130%);
    animation-delay: -0.2s;
  }

  .bar12 {
    transform: rotate(330deg) translate(0, -130%);
    animation-delay: -0.1s;
  }
}

@keyframes fade458 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.mb-medium {
  margin-bottom: 2.4rem;
}

.text-right {
  text-align: right;
}
