.reviews__head {
  @include responsive(1050px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
  }

  .reviews__search {
    @include responsive(1050px) {
      width: 100%;
      gap: 1.5rem;
    }
    @include responsive(520px) {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }

    .search__reviews {
      @include responsive(520px) {
        flex: 1 100%;
      }
    }

    .filter__reviews {
      margin: 0;
      select {
        @include responsive(1050px) {
          flex: 1;
        }
      }
    }

    #filter-reviews {
      select {
        @include responsive(1050px) {
          flex: 1;
        }
      }
    }
  }

  .saved {
    &__text {
      font-size: 1.4rem;
      font-weight: 400;
      margin-left: 1rem;
    }
  }
}


.saved__items {
  height: 100%;
  .category__products {
    grid-template-columns: repeat(5, 1fr);
    @include responsive(1150px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include responsive(1000px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include responsive(930px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include responsive(690px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include responsive(500px) {
      grid-template-columns: repeat(2, calc((100% - 1rem) / 2));
    }
  }
}
