.products-section {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__header {
    padding: 1.2rem 1.8rem;
    background-color: #3b7f04;
    color: #fff;
    border-radius: 6px;
    font-size: 1.8rem;
  }

  &__products {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.6rem;

    @media (max-width: 1250px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 1000px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 700px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 500px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 400px) {
      display: flex;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      & > div {
        flex: 0 0 22rem;
      }
    }
  }
}
