.popular {
  margin: 5rem 0;
  @include responsive(750px) {
    margin-left: unset;
    margin-right: unset;
  }

  &__categories {
    font-size: 2rem;
    font-weight: 500;
    color: $primary;
    @include responsive(435px) {
      font-size: 1.8rem;
    }
  }

  &__brands {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    min-height: 15rem;
    margin-top: 2rem;
    @include responsive(720px) {
      gap: 2rem;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &__name {
    height: 100%;
    @include responsive(720px) {
      width: calc((100% - 8rem) / 5);
    }
    @include responsive(590px) {
      width: calc((100% - 6rem) / 4);
    }
    @include responsive(465px) {
      width: calc((100% - 6rem) / 3);
      justify-self: center;
    }

    p {
      font-size: 1.6rem;
      font-weight: 400;
      color: $blackPopularCat;
      text-align: center;
      padding-top: 1.5rem;
      @include responsive(435px) {
        font-size: 1.4rem;
      }
    }

    img {
      height: 80%;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
