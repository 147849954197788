.sidebar {
  width: 29rem;
  height: 90rem;
  overflow: auto;
  padding: 4rem 2rem;
  padding-bottom: 2rem;
  background: #3b7f04;
  border-radius: 0.8rem;
  color: white;

  @include responsive(930px) {
    display: none;
  }

  .menu__list {
    list-style: none;
    margin: 2rem 0;

    .menu__list__item {
      padding: 0.8rem 2rem;
      margin-top: 0.6rem;
      color: $white;
      font-weight: 400;
      cursor: pointer;
      display: block;
      border-left: 0.2rem solid transparent;
      transition: border 0.3s;
    }

    .has__sublist {
      .orders__menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 0;
      }

      .sublist__menu {
        margin-left: 2rem;

        .sublist {
          font-size: 1.4rem;
          font-weight: 400;
          display: flex;
          align-items: center;

          // img {
          //     margin-right: 8px;
          // }
        }
      }

      .openMenu {
        display: block;
      }

      .closeMenu {
        display: none;
      }
    }

    .menu__list__item:hover,
    .active {
      // padding: 1rem 2rem;
      background: $sidebar-user;
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      color: $white;
      font-size: 1.8rem;
      font-weight: 500;
      border-left: 0.2rem solid $white;
    }
  }
}

// shows user__profile style only on mobile
.show__on__mobile {
  display: none;
  @include responsive(930px) {
    display: block;
    margin-bottom: 2rem;
  }
}

.user__profile {
  background: $sidebar-user;
  display: flex;
  padding: 2rem;
  font-size: 1.4rem;
  border-radius: 0.8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .users {
    width: 8rem;
    height: 8rem;
    background: $sidebar-user-bkg;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    ion-icon {
      color: $white;
      font-size: 4rem;
    }
  }

  p {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .bronze {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;
    color: #fff;

    &:hover {
      color: #e1e1e1;
    }
  }

  .user__mail {
    font-weight: 300;
  }

  .verification__box {
    background: $white;
    color: #555;
    padding: 1rem 2rem;
    border-radius: 0.5rem;

    margin-bottom: 1rem;
    p {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }

    .reason__verify {
      font-size: 12px;
      color: #314706;
    }
  }
  .center__verify {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: $white;

    .verified__img {
      margin-left: 0.5rem;
    }
  }
}

.verify-btn {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;

  width: 20rem;

  &__logo {
    background-color: #ffffff;

    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.1);

    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__text {
    background-color: #314706;
    color: #ffffff;

    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;

    padding: 1.2rem 0rem;

    font-weight: 500;
    font-size: 1.4rem;

  }
}