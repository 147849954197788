.banners {
  display: grid;
  grid-template-columns: 30rem 1fr 30rem;
  grid-template-rows: repeat(2, 1fr);
  gap: 1.2rem;

  height: 42rem;

  @media (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    height: 60rem;
  }

  & div {
    border-radius: 0.8rem;
    overflow: hidden;
    position: relative;

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: fill;
      transition: all 0.4s;
    }

    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1 / -1;

      @media (max-width: 1200px) {
        display: none;
      }

      @media (max-width: 650px) {
        display: block;
        grid-row: 2 / -1;
        grid-column: 1;
      }
    }

    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1 / -1;

      @media (max-width: 1200px) {
        grid-column: 1 / 3;
      }

      @media (max-width: 650px) {
        grid-row: 1;
        grid-column: 1 / -1;
      }
    }

    &:nth-child(3) {
      grid-column: 3;
      grid-row: 1;

      @media (max-width: 650px) {
        grid-row: 2;
        grid-column: 2;
      }
    }

    &:nth-child(3) {
      grid-column: 3;
      grid-row: 2;

      @media (max-width: 650px) {
        grid-row: 3;
        grid-column: 2;
      }
    }
  }
}

.banner {
  position: relative;

  &__slider-radio {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    height: fit-content !important;

    position: absolute !important;
    bottom: 2rem !important;
    top: auto !important;
    z-index: 300;
  }

  &__radio-banners {
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;

    cursor: pointer;

    background-color: white;
  }
}

.active-slide {
  background-color: darkgray !important;
}
