.kyc-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba($black, 0.4);

  &__container {
    width: 35%;
    height: fit-content;
    margin: auto;
    padding: 4rem;
    padding-top: 3rem;

    border-radius: 0.8rem;
    
    text-align: center;
    background: var(--insufficient-bg);

    font-size: 1.4rem;
    font-weight: "Red hat Display";
    @include responsive(930px) {
      width: 60%;
    }
    @include responsive(600px) {
      width: 80%;
      font-size: 1.3rem;
    }
    @include responsive(426px) {
      width: 90%;
      font-size: 1.2rem;
    }
    @include responsive(375px) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
}

&__close {
  display: flex;
  justify-content: flex-end;

  margin-right: auto;

  & span {
    cursor: pointer;
  }
}

&__close-icon {
   color: var(--login-input);
}

&__heading {
  display: flex;
    flex-direction: column;
    gap: 0.8rem;
    text-align: center; 

    margin-bottom: 2rem;
  
    & h3 {
      font-size: 3rem;

      font-weight: 700;
    }

    & p {
      font-size: 1.4rem;
      font-family: "Red hat Display";

      font-weight: 400;
      color: var(--kyc-subheading);
    }
}

&__main {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  padding: 3rem;
  padding-top: 0;
}

&__form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

}

&__step {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.8rem;

  padding: 1.2rem 2.4rem;
  background-color: var(--input-bg);
}

&__footer {
  color: #7BB20E;
  margin-top: 2rem;

  & p {
    margin-top: 1rem;
    cursor: pointer;
  }
}

&__error-display {
  font-size: 1.3rem;

  display: block;
  
  margin-bottom: 2rem;
  margin-top: -1rem;

  color: #FF5B2B;

}

.button {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    cursor: pointer;

    width: 100%;
    padding: 1.3rem 1rem;
  
    background-color: #DBDBDB;
    color: #A3A3A3;
    
    border: none;
    border-radius: 0.4rem;
  
    font-weight: 500;

    &--active {
      background-color: #7BB20E;
      color: #ffffff;

    }
}

&__bvn-form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;

  & p {
    color: Dark Gray;
    font-size: 1.4rem;
    font-weight: 400;

    text-align: left;
  }

}

&__bvn-input {
  background-color: var(--input-bg);

  border: 1px solid var(--kyc-input-border);
  border-radius: 0.8rem;

  padding: 1.6rem;
}

&__notes {
  background-color: var(--input-bg);
  color: #7D7D7D;

  padding: 0.8rem 1.2rem;

  border-radius: 0.8rem;

  font-size: 1.2rem;
  text-align: left;
}

&__reviewing {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  background-color: var(--kyc-reviewingbg);
  color: #FFA800;

  font-size: 1.2rem;
  font-family: "lato";
  font-weight: 400;

  border-radius: 0.8rem;

  padding: 0.4rem 1.2rem;
}

&__img-capture {
  width: 100%;
  height: 38rem;
  background-color: #1e1e1e;
  display: block;
  object-fit: fill; }
}