.form__auth {
  padding: 2.4rem;

  // overflow: auto;
  // flex: 1;
  // font-size: 1.4rem;

  // display: flex;
  // flex-direction: column;
  // justify-content: center;

  // @include responsive(768px) {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   padding: 2.4rem;
  //   // background-color: rgba($color: #000000, $alpha: 0.6);
  // }
}

.login__auth {
  @media (min-width: 1440px) {
    position: relative;
    top: 10%;
  }
}

.auth {
  // flex: 1;
  // padding: 0 2rem;

  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;

    margin-bottom: 2.4rem;

    & button {
      padding: 0 1.8rem 6px;
      border-bottom: 3px solid transparent;
      outline: none;

      transition: all 0.3s;

      &:hover {
        color: #7bb20e;
      }

      &.active {
        color: #7bb20e;
        border-bottom: 3px solid currentColor;
      }
    }
  }

  &__container {
    height: 100vh;
    width: 100vw;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: initial;

    padding: 7.2rem 2.4rem;

    overflow-y: scroll;
  }

  &__photo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    // @include responsive(768px) {
    //   display: none;
    // }
  }

  &__main {
    // width: 55rem;
    // max-width: 100%;
    // margin: auto;

    // background-color: #f4f9ec;
    // padding: 4.8rem 0;
    // border-radius: 1.2rem;
    // background-color: var(--login-bg);

    width: 50rem;
    max-width: 100%;
    background-color: var(--login-bg);
    padding: 4.8rem 0;
    border-radius: 1.2rem;

    &--1 {
      width: 60rem;
      padding: 5.6rem 0;
      // border-radius: 1.8rem;

      // @media (max-width: 500px) {
      //   padding: 5.6rem 3.6rem;
      // }
    }

    // margin: 4rem auto;
    // width: 80%;

    // @include responsive(1024px) {
    //   width: 90%;
    // }
    // @include responsive(768px) {
    //   width: 70%;
    // }
    // @include responsive(600px) {
    //   width: 0%;
    // }
    // @include responsive(500px) {
    //   width: 100%;
    // }
  }

  &__img-block {
    text-align: center;
    margin-bottom: 2.4rem;
  }

  &__img {
    display: inline-block;
    height: 5.6rem;
  }

  // &__card {
  //   background-color: $white;
  //   box-shadow: 0 1.2rem 2.4rem rgba(#000, 0.1);
  //   border-radius: 0.8rem;
  // }

  &__card-main {
    max-width: 80%;
    margin: auto;
    // padding: 4.8rem 0 7.2rem;

    @include responsive(600px) {
      max-width: 85%;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    text-align: center;
    margin-bottom: 1rem;
  }

  &__heading {
    font-size: 2.4rem;
    color: $black;
    font-weight: 600;
    color: var(--details-text);
  }

  &__subheading {
    color: var(--reset-text);
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    margin-bottom: 3.6rem;
  }

  &__platforms {
    display: flex;
    gap: 1.2rem;
    @include responsive(570px) {
      flex-direction: column;
    }
  }

  &__platform {
    flex: 1;
    padding: 1.6rem;
    font-family: inherit;
    background-color: transparent;
    border: 1px solid $grey9;
    border-radius: 0.6rem;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;

    &__img {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
    }

    &--2 {
      color: #4368c7;
      background-color: #f3f6fb;
    }
  }

  &__or {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    color: #c0c0c0;

    & div {
      height: 0.5px;
      background-color: #c0c0c0;
      flex: 1;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__footer {
    text-align: center;
    font-weight: 500;

    & a {
      &:link,
      &:visited {
        text-decoration: none;
        color: $red1;
      }
    }
  }

  &__footer1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;
    font-weight: 500;
    font-size: 12px;

    @include responsive(320px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    & a {
      text-decoration: none;
      color: $lemon;
    }

    & div {
      display: flex;
      align-items: center;
      gap: 0.6rem;
    }
  }
}
