@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  font-size: 62.5%;
  @include responsive(750px) {
    font-size: 60%;
  }
  @include responsive(600px) {
    font-size: 55%;
  }
  @include responsive(400px) {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
  font-size: 1.6rem;
  background: $grey1;
  font-family: "Red Hat Display", sans-serif;
}

.page__body {
  background-color: $grey1;
  min-height: 50vh;
  margin-top: 13rem;
  @include responsive(650px) {
    margin-top: 17rem;
  }
}
