.loaderVBars {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;

  &--sm {
    transform: scale(0.5) translateY(-50%);
  }

  & div {
    display: inline-block;
    position: absolute;
    left: 0.8rem;
    width: 1.6rem;
    background: #3b7f04;
    animation: loaderVBars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  & div:nth-child(1) {
    left: 0.8rem;
    animation-delay: -0.24s;
  }

  & div:nth-child(2) {
    left: 3.2rem;
    animation-delay: -0.12s;
  }

  & div:nth-child(3) {
    left: 5.6rem;
    animation-delay: 0;
  }
}

.placeholder {
  background-color: var(--btn-bg) !important;
  color: transparent !important;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: anim-placeholder;
  animation-timing-function: ease-in-out;

  & * {
    color: transparent !important;
  }
}