.dash__header {
  display: flex;

  .dash__head {
    display: flex;
    align-items: center;

    h4 {
      font-size: 2.5rem;
      font-weight: 700;
      @include responsive(790px) {
        font-size: 2rem;
      }
      @include responsive(500px) {
        font-size: 1.8rem;
      }
    }

    ion-icon {
      margin-right: 1rem;
      cursor: pointer;
    }
  }
}
