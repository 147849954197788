.cart {
  margin-top: 3rem;
  margin-bottom: 3rem;

  &__troy {
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
  }

  &__part {
    flex-basis: 70%;
    background-color: $white;
    border-radius: 3px;
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__parts {
    flex-basis: 70%;
  }

  &__img {
    display: flex;
    justify-content: center;
  }

  &__roll {
    width: 18rem;
  }

  &__text {
    font-size: 2.6rem;
    font-weight: 700;
    padding-top: 3rem;
  }

  &__browse {
    font-size: 1.7rem;
    font-weight: 400;
    color: $ftBlackSec;
    padding-top: 0.5rem;
  }

  &__btn {
    padding-top: 2rem;

    .click {
      font-size: 1.6rem;
      font-weight: 600;
      color: $white;
      background-color: $lemon;
      padding: 1rem 8.3rem;
      border-radius: 0.5rem;
    }
  }
}

.items {
  font-size: 2.6rem;
  font-weight: 700;
  color: $ftBlackSec;
  margin-bottom: 1rem;
  flex-basis: 70%;

  &__names {
    padding-right: 1rem;
  }

  &__name {
    padding-left: 0.6rem;
  }
}

.canon {
  display: flex;
  align-items: center;
  background-color: $white;
  padding: 1.6rem 0 1rem 2rem;
  border-radius: 0.4rem;

  &__table {
    width: 100%;
  }

  &__page {
    border: 1px solid $black;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &_pink {
    width: 7rem;
  }

  &__name {
    padding-left: 2rem;
    padding-right: 3rem;

    .eos {
      font-size: 1.5rem;
      font-weight: 600;
      color: $ftBlackSec;
    }

    .black {
      font-size: 1.3rem;
      font-weight: 400;
      color: $ftBlackSec;

      .blackk {
        color: $lemon;
      }
    }
  }

  &__prices {
    margin-left: 6.5rem;
    text-align: left;

    .fig {
      font-size: 1.5rem;
      font-weight: 500;
      color: $ftBlackSec;
    }

    .figg {
      border: 1px solid #dddddd;
      border-radius: 0.5rem;
      font-size: 1.5rem;
      font-weight: 500;
      background-color: $white;
      // padding: 1px 6px;
    }
  }
}

.canonn {
  display: flex;
  margin-top: 1rem;
  padding: 1.6rem 0 1rem 2rem;
  border-radius: 0.3rem;
  align-items: center;
  margin-top: 2rem;
}

.order {
  flex-basis: 30%;
  background-color: $white;
  border-radius: 0.3rem;
  padding: 2rem 2rem 10rem 2rem;

  &__summary {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 1.8rem 1.4rem 0.8rem 1.4rem;
  }

  &__text {
    font-size: 1.8rem;
    font-weight: 700;
    color: $ftBlackSec;
    padding-bottom: 0.5rem;
    border-bottom: 1.3px solid #e6e6e6;
  }

  &__check {
    text-align: center;
    padding-top: 3rem;

    .checkout {
      background-color: #bcbcbc;
      color: $white;
      font-size: 1.6rem;
      font-weight: 500;
      padding: 1.2rem 9.5rem;
      border-radius: 0.4rem;
    }

    .checked {
      background-color: $lemon;
      color: $white;
      font-size: 1.6rem;
      font-weight: 500;
      padding: 1.2rem 9.5rem;
      border-radius: 0.4rem;
    }
  }
}

.coupon {
  font-size: 1.4rem;
  font-weight: 500;
  color: $ftBlackSec;

  &__code {
    border: 1px solid #e6e6e6;
    padding: 1.5rem 4.5rem;
    border-radius: 0.4rem;
  }

  &__grand {
    font-size: 1.8rem;
    font-weight: 700;
    color: $ftBlackSec;
    padding-top: 1rem;
  }
}

.summary__order {
  padding-bottom: 1rem;
  border-bottom: 1.3px solid #e6e6e6;
  border-radius: 0.4rem;
}
