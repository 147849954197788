.notifications-block {
  position: fixed;
  top: 12.8rem;
  right: 0;

  height: 70vh;
  width: 45rem;
  max-width: 100%;
  z-index: 99999999999;

  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: var(--navtab-bg);

  transform: translateX(100%);
  transition: all 0.3s;

  display: flex;
  flex-direction: column;

  @media (max-width: 1150px) {
    top: 8rem;
  }

  @media (max-width: 750px) {
    top: 14rem;
  }

  &--show {
    transform: translateX(0);
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.8rem;

    padding: 1.8rem 2.4rem;

    font-weight: 500;
    font-size: 1.6rem;
    box-shadow: 0 8px 64px rgba($color: #000000, $alpha: 0.07);
    // background-color: #f7f7f7;
    // border-bottom: 1px solid var(--border);

    .clear__notification__box {
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 2rem;
      .clear__notification {
        font-size: 14px;
        color: #7bb20e;
        cursor: pointer;
      }
      & ion-icon {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  &__close {
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__main {
    flex: 1;

    padding: 1.8rem 2.4rem;

    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__block-header {
    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &__block-heading {
  }

  &__divider {
    flex: 1;
    height: 0.5px;
    background-color: #eaeaea;
  }

  &__notifications {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__info {
    padding: 1.2rem;
    text-align: center;
  }

  &__footer {
    padding: 1.8rem 2.4rem;
    text-align: center;
    // border-top: 1px solid var(--border);
    cursor: pointer;
    box-shadow: 0 8px 64px rgba($color: #000000, $alpha: 0.07);

    // &:hover {
    //   background-color: var(--table-hover);
    // }
  }
}

.notification1 {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 0.5rem;

  padding: 1.2rem;
  //   border-bottom: 1px solid var(--border);
  cursor: pointer;
  border-radius: 0.8rem;

  transition: all 0.1s;

  // background: var(--notification-bg2);

  //   &:hover {
  //     background-color: var(--table-hover);
  //   }

  // &:nth-child(even) {
  //   background-color: #f7faf5;
  //   background-color: var(--notification-bg);
  // }

  &__btn {
    flex-shrink: 0;

    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border: none;
    border-radius: 50%;
  }
  .unread__btn {
    background-color: #7bb20e;
  }

  .read__btn {
    background-color: var(--body-bg);
  }
  &__text {
    flex: 1;

    font-size: 1.35rem;
  }

  &__dates {
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2px;

    font-size: 1.35rem;

    & > *:nth-child(2) {
      font-size: 1.2rem;
    }
  }
}

.unread__notification {
  background-color: #f7faf5;
  background-color: var(--notification-bg);
}

.notifications-block-1 {
  position: fixed;
  top: 12.8rem;
  right: 0;
  height: 70vh;
  width: 45rem;
  max-width: 100%;
  z-index: 99999999999;

  background-color: #fff;

  transform: translateX(100%);
  transition: all 0.3s;

  display: flex;
  flex-direction: column;

  @media (max-width: 1150px) {
    top: 8rem;
  }

  @media (max-width: 750px) {
    top: 12.8rem;
  }

  &--show {
    transform: translateX(0);
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.8rem;

    padding: 1.8rem 2.4rem;

    font-weight: 500;
    font-size: 1.6rem;
    border-bottom: 1px solid #eaeaea;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__close {
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__main {
    flex: 1;

    padding: 1.8rem 2.4rem;

    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__block-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
  }

  &__block-heading {
    padding: 0.8rem 2.4rem;
    background-color: #eaeaea;
    border-radius: 0.8rem;
  }

  &__divider {
    flex: 1;
    height: 0.5px;
    background-color: #eaeaea;

    display: none;
  }

  &__notifications {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__info {
    padding: 1.2rem;
    text-align: center;
  }

  &__footer {
    padding: 1.8rem 2.4rem;
    text-align: center;
    border-top: 1px solid #eaeaea;
    cursor: pointer;
  }
}
