.product-card {
  background-color: var(--navtab-bg);
  border: 1px solid var(--checkout-border);
  border-radius: 0.8rem;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__header {
    height: 20rem;
    position: relative;
    overflow: hidden;
  }

  &__new {
    background-color: #ff784b;
    color: #fff;
    position: absolute;
    top: -0.4rem;
    left: -4.8rem;
    padding: 1.6rem 4.8rem 0.8rem;
    text-align: center;
    transform: rotate(-55deg);
    text-transform: uppercase;
    font-size: 1.2rem;
    z-index: 9;
  }

  &__discount {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    font-size: 1rem;
    background-color: #fce6e4;
    color: #f64f25;
    padding: 3px 6px;
    border-radius: 4px;
    z-index: 9;

    &--ld {
      background-color: none;
      color: transparent;
      background-color: #ccc;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.4s;
  }

  &__body {
    padding: 1.2rem;
  }

  &__slider-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    margin: 1.8rem 0 0.6rem;

    & span {
      width: 16px;
      height: 4px;
      border-radius: 10rem;
      background-color: #ecf2e6;
      cursor: pointer;

      &.active {
        background-color: #659837;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__name {
    font-size: 1.7rem;
    font-weight: 600;
    color: var(--text-color);
  }

  &__flex {
    display: flex;
    align-items: center;
    gap: 1.8rem;
  }

  &__del-price {
    color: #909090;
    text-decoration: line-through;
  }

  &__price {
    font-size: 1.5rem;
    color: #000;
    font-weight: 600;
    color: var(--text-color);
  }
}
