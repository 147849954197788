.close__icon {
  font-size: 2rem;
  cursor: pointer;
}

.enter {
  &__body {
    margin: auto;
    width: 35%;
    padding: 4rem 6rem;
    border-radius: 5px;
    background-color: var(--insufficient-bg);

    @include responsive(1295px) {
      width: 45%;
    }
    @include responsive(1115px) {
      padding: 4rem 7rem;
    }
    @include responsive(1070px) {
      padding: 4rem 6rem;
      width: 50%;
    }
    @include responsive(968px) {
      width: 55%;
    }
    @include responsive(768px) {
      width: 70%;
    }
    @include responsive(628px) {
      width: 80%;
    }
    @include responsive(544px) {
      padding: 3rem 5rem;
      width: 85%;
    }
    @include responsive(493px) {
      width: 90%;
    }
    @include responsive(375px) {
      width: 90%;
      padding: 4rem 2rem 3rem;
    }
  }

  &__content {
    text-align: center;
  }

  &__text {
    font-size: 30px;
    font-weight: 700;
    color: var(--help-text);
    @include responsive(848px) {
      font-size: 24px;
    }
    @include responsive(493px) {
      font-size: 20px;
    }
  }

  &__textt {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    padding-top: 6px;
    padding-bottom: 3rem;
    color: var(--help-text);
    @include responsive(848px) {
      font-size: 12px;
    }
  }
}
.submit__phone__number {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-mediums {
  &__heading {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1.6rem;
    margin-top: 3rem;
  }
}
.form-inputt-group {
  display: flex;
  height: 4.8rem;
  border-radius: 0.6rem;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ebebeb;
  margin-top: 2rem;
  background-color: var(--input-bg);

  border: 1px solid var(--login-border);

  & input {
    flex: 1;
    font-family: inherit;
    outline: none;
    min-width: 0;
    color: $black;
    padding: 0 1.2rem;
    color: var(--form-color);

    &::placeholder {
      color: $grey10;
      color: var(--form-placeholder);
    }
  }

  & button {
    background-color: #7bb20e;
    padding: 0 1.6rem;
    color: #fff;
  }

  &--1 button {
    background-color: #f1f1f1;
    color: #000;
    background-color: var(--input-num);
    color: var(--from-color);
  }
}
.submit {
  background-color: $lemon;
  padding: 1rem 4rem;
  font-size: 14px;
  font-weight: 600;
  color: $white;
  border-radius: 3px;
  margin-top: 2rem;
}
