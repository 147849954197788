.transact {
  &__body {
    margin: auto;
    width: 35%;
    padding: 4rem 6rem;
    background-color: #f9faf8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    background-color: var(--insufficient-bg);

    @include responsive(1295px) {
      width: 45%;
    }
    @include responsive(1115px) {
      padding: 4rem 7rem;
    }
    @include responsive(1070px) {
      padding: 4rem 6rem;
      width: 50%;
    }
    @include responsive(968px) {
      width: 55%;
    }
    @include responsive(768px) {
      width: 70%;
    }
    @include responsive(628px) {
      width: 80%;
    }
    @include responsive(544px) {
      padding: 3rem 5rem;
      width: 85%;
    }
    @include responsive(493px) {
      width: 90%;
    }
    @include responsive(375px) {
      width: 90%;
      padding: 2rem;
    }
  }
  &__heading {
    font-size: 2rem;
    color: var(--chev-btn);
    padding-bottom: 2rem;
  }

  &__info {
    padding: 2rem 4rem;
    background-color: #fff;
    background-color: var(--pay-bg);
    border-radius: 0.4rem;
    width: 100%;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    justify-self: center;
    // gap: 4rem;

    //  background-color: #fff;

    line-height: 2;
  }

  &__text {
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--transact-text);

    @include responsive(375px) {
      font-size: 1.4rem;
    }
  }
  &__date {
    color: var(--chev-btn);
    font-size: 1.6rem;
    font-weight: 500;

    @include responsive(375px) {
      font-size: 1.4rem;
    }
  }

  &__close {
    margin-top: 4rem;
    .close__btn {
      font-size: 1.6rem;
      background-color: #7bb20e;
      color: #fff;
      padding: 1rem 5rem;
      border-radius: 0.4rem;
    }
  }
}
