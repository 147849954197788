.explore {
  &__body {
    margin: 2rem auto;

    @include responsive(650px) {
      overflow-x: scroll;
    }
  }

  &__part {
    display: flex;
    gap: 1.5rem;
    justify-content: space-evenly;

    @include responsive(650px) {
      overflow-x: scroll;
      width: 120rem;
    }
  }
}
