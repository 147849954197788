$uiMode: light;

$body-bg: if($uiMode == light, "#efefef", "#0F241A");
// $footer-bg: if($uiMode == light, "#232323", "#1A1A1A");
$footer-bg: if($uiMode == light, "#232323", "#07120D");
$login-bg: if($uiMode == light, "#f4f9ec", "#163627");
$login-border: if($uiMode == light, "#ebebeb", "#1E4834");
$login-input: if($uiMode == light, "#000", "#ffffff");
$text-color: if($uiMode == light, "#2B2B2B", "#D9D9D9");
$text-slashed: if($uiMode == light, "#909090", "#909090");
$header-bg: if($uiMode == light, "#FFF", "#0F241A");
$header-border: if($uiMode == light, "#eee", "#1E4834");
$drop-hover: if($uiMode == light, "#7bb20e", "#1E4834");
$menu-hover: if($uiMode == light, "#f1f1f1", "#1E4834");
$navtab-bg: if($uiMode == light, "#FFF", "#163627");
$loader: if($uiMode == light, "#a9a9a9", "#163627");
$loader-light: if($uiMode == light, "#ddd", "#2d4a3d");
$loader-dark: if($uiMode == light, "#d0d0d0", "#143123");
$search-bg: if($uiMode == light, "#d8d8d8", "#0A1912");
$search-header: if($uiMode == light, "none", "#0A1912");
$search-border: if($uiMode == light, "#d8d8d8", "#1E4834");
$add-btn: if($uiMode == light, "none", "#0A1912");
$btn-bg: if($uiMode == light, "none", "#D9D9D9");
$sidebar-border: if($uiMode == light, "#eaeaea", "#1E4834");
$prod-title: if($uiMode == light, "#545454", "#D9D9D9");
$prod-text: if($uiMode == light, "#545454", "#cccccc");
$prod-border: if($uiMode == light, "#d8d8d8", "1E4834");
$chev-btn: if($uiMode == light, "#141414", "#d9d9d9");
$select-bg: if($uiMode == light, "none", "1E4834");
$cart-btn: if($uiMode == light, "#ffffff", "none");
$cart-border: if($uiMode == light, "#e6e6e6", "#1E4834");
$similar-border: if($uiMode == light, "#D8D8D8", "#444444");
$checkout-border: if($uiMode == light, "#E6E6E6", "#1E4834");
$check-border: if($uiMode == light, "#d0d0d0", "#1E4834");
$choose-bg: if($uiMode == light, "#efefef", "#163627");
$account-text: if($uiMode == light, "#555555", "#A6A6A6");
$input-num: if($uiMode == light, "#f1f1f1", "#314607");
$input-bg: if($uiMode == light, "#ffffff", "#0A1912");
$mail-bg: if($uiMode == light, "#ededed", "#4d4d4d");
$rank-fig: if($uiMode == light, "#B0B0B0", "#737373");
$rank-info: if($uiMode == light, "#b0b0b0", "#d9d9d9");
$order-text: if($uiMode == light, "#808080", "#A6A6A6");
$deliver-bg: if($uiMode == light, "#edf2fa", "#182E4E");
$deliver-border: if($uiMode == light, "#D9D9D9", "#1E4834");
$deliver-border2: if($uiMode == light, "#cccccc", "#1E4834");
$pay-bg: if($uiMode == light, "#ffffff", "#1B412F");
$note-text: if($uiMode == light, "#808080", "#999999");
$insufficient-bg: if($uiMode == light, "#f9faf8", "#163627");
$insufficient-text: if($uiMode == light, "#727272", "#999999");
$insufficient-num: if($uiMode == light, "#fafafa", "#0A1912");
$thanks-details: if($uiMode == light, "#fcfcee", "#57570F");
$rate-product: if($uiMode == light, "#ffffff", "#none");
$rate-text: if($uiMode == light, "#000", "#999999");
$form-color: if($uiMode == light, "#000", "#d9d9d9");
$form-placeholder: if($uiMode == light, "#bebecb", "#595959");
$wallet-bg: if($uiMode == light, "#fafafa", "#1B412F");
$order-bg: if($uiMode == light, "#efefef", "#0F241A");
$order-border: if($uiMode == light, "#dddddd", "#1E4834");
$pending-bg: if($uiMode == light, "#fbf4d0", "#403C26");
$cancelled-bg: if($uiMode == light, "#Fdf2f1", "#402726");
$processing-bg: if($uiMode == light, "#fcdecf", "#402F26");
$shipped-bg: if($uiMode == light, "#CCE0FF", "#263040");
$details-grey: if($uiMode == light, "#eaeaea", "#163627");
$details-green: if($uiMode == light, "#E1FFCC", "#314026");
$green-text: if($uiMode == light, "#397305", "#61C309");
$details-blue: if($uiMode == light, "#cce0ff", "#263040");
$details-text: if($uiMode == light, "#000", "#d9d9d9");
$details-info: if($uiMode == light, "#000", "#163627");
$details-total: if($uiMode == light, "#edf2fa", "#214378");
$delivery-address: if($uiMode == light, "#edf2fa", "#162C50");
$delivery-border: if($uiMode == light, "#bebecb", "#1E4834");
$change-default: if($uiMode == light, "#edf2fa", "#2C6ED1");
$phone-bg: if($uiMode == light, "#f1fcdc", "#314607");
$input-border: if($uiMode == light, "#e6e6e6", "#1E4834");
$help-text: if($uiMode == light, "#444444", "#d9d9d9");
$help-hover: if($uiMode == light, "#444444", "#444444");
$about-text: if($uiMode == light, "#545454", "#A6A6A6");
$about-list: if($uiMode == light, "#909090", "#A6A6A6");
$about-shadow: if($uiMode == light, "#cccccc4d", "#141414");
$contact-border: if($uiMode == light, "#dddcdc", "#1E4834");
$terms-text: if($uiMode == light, "#545454", "#B3B3B3");
$notification-bg: if($uiMode == light, "#f7faf5", "#1B412F");
$notification-bg2: if($uiMode == light, "none", "#214F39");
$sign-bg: if($uiMode == light, "#f4f9ec", "#163627");
$invalid-bg: if($uiMode == light, "#ffffff", "#241500");
$reset-text: if($uiMode == light, "#808080", "#D9D9D9");
$input-color: if($uiMode == light, "#000000", "#ffffff");
$verify-close: if($uiMode == light, "#efefef", "#0A1912");
$transact-text: if($uiMode == light, "#737373", "#bebebe");
$kyc-reviewingbg: if($uiMode == light, "#FFF6E4", "#332200");
$kyc-subheading: if($uiMode == light, "#7D7D7D", "#999999");
$kyc-input-border: if($uiMode == light, "#e9e9e9", "#163627");

// 143123 - dark;
// 2d4a3d - light;

:root {
  --body-bg: #{$body-bg};
  --footer-bg: #{$footer-bg};
  --login-bg: #{$login-bg};
  --login-border: #{$login-border};
  --login-input: #{$login-input};
  --text-color: #{$text-color};
  --text-slash: #{$text-slashed};
  --header-bg: #{$header-bg};
  --header-border: #{$header-border};
  --drop-hover: #{$drop-hover};
  --menu-hover: #{$menu-hover};
  --navtab-bg: #{$navtab-bg};
  --loader: #{$loader};
  --loader-light: #{$loader-light};
  --loader-dark: #{$loader-dark};
  --search-bg: #{$search-bg};
  --search-header: #{$search-header};
  --search-border: #{$search-border};
  --add-btn: #{$add-btn};
  --btn-bg: #{$btn-bg};
  --sidebar-border: #{$sidebar-border};
  --prod-title: #{$prod-title};
  --prod-text: #{$prod-text};
  --prod-border: #{$prod-border};
  --chev-btn: #{$chev-btn};
  --select-bg: #{$select-bg};
  --cart-btn: #{$cart-btn};
  --cart-border: #{$cart-border};
  --similar-border: #{$similar-border};
  --checkout-border: #{$checkout-border};
  --check-border: #{$check-border};
  --choose-bg: #{$choose-bg};
  --account-text: #{$account-text};
  --input-num: #{$input-num};
  --input-bg: #{$input-bg};
  --mail-bg: #{$mail-bg};
  --rank-fig: #{$rank-fig};
  --rank-info: #{$rank-info};
  --order-text: #{$order-text};
  --deliver-bg: #{$deliver-bg};
  --deliver-border: #{$deliver-border};
  --deliver-border2: #{$deliver-border2};
  --pay-bg: #{$pay-bg};
  --note-text: #{$note-text};
  --insufficient-bg: #{$insufficient-bg};
  --insufficient-text: #{$insufficient-text};
  --insufficient-num: #{$insufficient-num};
  --thanks-details: #{$thanks-details};
  --rate-product: #{$rate-product};
  --rate-text: #{$rate-text};
  --form-color: #{$form-color};
  --form-palceholder: #{$form-placeholder};
  --wallet-bg: #{$wallet-bg};
  --order-bg: #{$order-bg};
  --order-border: #{order-border};
  --pending-bg: #{$pending-bg};
  --cancelled-bg: #{$cancelled-bg};
  --processing-bg: #{$processing-bg};
  --shipped-bg: #{shipped-bg};
  --details-grey: #{$details-grey};
  --details-green: #{$details-green};
  --green-text: #{$green-text};
  --details-blue: #{$details-blue};
  --details-text: #{$details-text};
  --details-info: #{$details-info};
  --details-total: #{$details-total};
  --delivery-address: #{$delivery-address};
  --delivery-border: #{$delivery-border};
  --change-default: #{$change-default};
  --phone-bg: #{$phone-bg};
  --input-border: #{$input-border};
  --help-text: #{$help-text};
  --help-hover: #{$help-hover};
  --about-text: #{$about-text};
  --about-shadow: #{$about-shadow};
  --contact-border: #{$contact-border};
  --terms-text: #{$terms-text};
  --about-list: #{$about-list};
  --notification-bg: #{$notification-bg};
  --notification-bg2: #{$notification-bg2};
  --sign-bg: #{$sign-bg};
  --invalid-bg: #{$invalid-bg};
  --reset-text: #{$reset-text};
  --input-color: #{$input-color};
  --verify-close: #{$verify-close};
  --transact-text: #{$transact-text};
  --kyc-subheading: #{$kyc-subheading};
  --kyc-reviewingbg: #{$kyc-reviewingbg};
  --kyc-input-border: #{$kyc-input-border};
}

$color-danger: #ff6b6b;
$color-warning: #f08c00;
$color-success: #51cf66;
