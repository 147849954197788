.welcome {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($black, 0.4);

  &__page {
    width: 40%;
    margin: auto;
    padding: 4rem;
    padding-top: 3rem;
    padding-bottom: 6rem;
    border-radius: 0.4rem;
    height: fit-content;
    text-align: center;
    background: var(--navtab-bg);
    font-size: 1.4rem;
    @include responsive(930px) {
      width: 60%;
    }
    @include responsive(600px) {
      width: 80%;
      font-size: 1.3rem;
    }
    @include responsive(426px) {
      width: 90%;
      font-size: 1.2rem;
    }
    @include responsive(375px) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    .message {
      margin: 1rem 0;
    }

    &__close__icon {
      float: right;
      font-size: 2rem;
      display: flex;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: var(--verify-close);
    }
    &__close__icon:hover {
      cursor: pointer;
    }

    &__name {
      font-size: 2.4rem;
      margin-top: 3rem;
      font-weight: 700;
      @include responsive(426px) {
        font-size: 1.8rem;
      }

      .name {
        color: $lemon;
        font-size: 2.8rem;
        @include responsive(426px) {
          font-size: 2.2rem;
        }
      }
    }

    .verify__account {
      color: $red3;
      font-size: 1.2rem;
    }

    .welcome__btns {
      width: 80%;
      display: flex;
      gap: 2rem;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 2rem;

      .close__welcome {
        border-radius: 4px;
        padding: 1.2rem 8rem;
        background: var(--verify-close);
        color: var(--input-color);
        @include responsive(1440px) {
          flex: 1;
          padding: 1.2rem 0;
        }
        @include responsive(1120px) {
          display: none;
        }
      }

      .verify__account__btn {
        background: $lemon;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
