.reviews {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 2rem;
      font-weight: 700;
    }

    //   this search and sort section repeating here

    .reviews__search {
      display: flex;
      align-items: center;
      font-size: 1.4rem;

      .search__reviews {
        width: 23rem;
        background: $white;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 0.4rem;
        input {
          width: 100%;
          margin-left: 1rem;
        }
      }

      .filter__reviews {
        margin-left: 2rem;
        display: flex;
        align-items: center;

        select {
          padding: 0.5rem 1rem;
          border-radius: 0.4rem;
          margin-left: 1rem;
          background: $white;
          background: var(--pay-bg);
        }
      }
    }
  }

  .all__reviews {
    display: flex;
    gap: 1.5rem;
    height: 100%;
    margin-top: 2rem;
    @include responsive(620px) {
      flex-direction: column;
    }

    .reviews__list {
      color: $default-address;
      font-size: 1.4rem;
      flex: 5;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include responsive(620px) {
        flex: 1;
      }

      .review__item {
        padding: 1.5rem 2rem;
        background: $white;
        border-radius: 0.8rem;
        background: var(--navtab-bg);
        @include responsive(740px) {
          padding: 1rem;
        }

        &__prod {
          display: flex;
          gap: 1rem;
          cursor: pointer;
          @include responsive(740px) {
            justify-content: space-between;
          }
          @include responsive(620px) {
            justify-content: flex-start;
          }

          .review__img {
            padding: 0.7rem 0.5rem;
            background: $order-price-bkg;
            border-radius: 0.8rem;
          }

          .review__prod {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            justify-content: flex-start;

            &__name {
              font-weight: 600;
              color: $black;
              color: var(--details-text);
            }
          }
        }

        .review__delivery__time {
          display: flex;
          font-size: 1.2rem;
          justify-content: flex-end;
        }
      }

      .active {
        border: 0.1rem solid $sidebar-user-bkg;
        border-left-width: 0.3rem;
      }
    }
    .reviews__details,
    .mobile__review {
      background: $white;
      padding: 3rem;
      border-radius: 0.8rem;
      background: var(--navtab-bg);
    }

    .reviews__details {
      height: 400px;
      flex: 6;
      @include responsive(740px) {
        flex: 4.5;
      }
      @include responsive(620px) {
        flex: 1;
      }
      @include responsive(620px) {
        display: none;
      }
    }
    .star {
      pointer-events: none;
    }

    .customer__review {
      font-weight: 600;
    }

    p {
      font-size: 1.4rem;
      margin-top: 2rem;
      color: $default-address;
      color: var(--order-text);
    }

    .review__date {
      margin-top: 2rem;
      font-size: 1.2rem;
      display: flex;
      justify-content: flex-end;
    }
    .mobile__review {
      display: none;
      @include responsive(620px) {
        display: block;
      }
    }
    .show__review__mobile {
      @include responsive(620px) {
        display: block;
      }
    }
    .hide__review__mobile {
      display: none;
    }
  }
}
