.remove {
  &__body {
    margin: auto;
    width: 35%;
    padding: 4rem 8rem;
    background-color: #f9faf8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    background-color: var(--insufficient-bg);
    @include responsive(1295px) {
      width: 45%;
    }
    @include responsive(1115px) {
      padding: 4rem 7rem;
    }
    @include responsive(1070px) {
      padding: 4rem 6rem;
      width: 50%;
    }
    @include responsive(968px) {
      width: 55%;
    }
    @include responsive(768px) {
      width: 70%;
    }
    @include responsive(628px) {
      width: 80%;
    }
    @include responsive(544px) {
      padding: 3rem 5rem;
      width: 85%;
    }
    @include responsive(493px) {
      width: 90%;
    }
    @include responsive(375px) {
      width: 90%;
      padding: 2rem;
    }
  }

  &__content {
    text-align: center;
  }
  &__text {
    font-size: 26px;
    font-weight: 600;
    color: var(--help-text);
    @include responsive(848px) {
      font-size: 24px;
    }
    @include responsive(493px) {
      font-size: 20px;
    }
  }
  &__textt {
    font-size: 13px;
    color: var(--help-text);
    font-weight: 400;
    padding-top: 1rem;
    @include responsive(848px) {
      font-size: 12px;
    }
  }
}

.confirm__detail {
  padding-top: 3rem;
}
.cancel__confirm {
  background-color: $lemon;
  padding: 1rem 4rem;
  font-size: 14px;
  font-weight: 600;
  color: $white;
  border-radius: 3px;
  margin-right: 2rem;
  @include responsive(848px) {
    font-size: 12px;
  }
  @include responsive(493px) {
    padding: 1rem 3rem;
  }
}
.remove__confirm {
  background-color: #f2f2f2;
  padding: 1rem 4rem;
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  border-radius: 3px;
  @include responsive(848px) {
    font-size: 12px;
  }
  @include responsive(493px) {
    padding: 1rem 3rem;
  }
}
