@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700;800;900&display=swap");

html {
  font-size: 62.5%;

  @media (max-width: 800px) {
    font-size: 55%;
  }

  @media (max-width: 450px) {
    font-size: 50%;
  }

  @media (max-width: 350px) {
    font-size: 45%;
  }
}

body {
  background-color: var(--body-bg);

  font-size: 1.4rem;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  color: var(--text-color);
  line-height: 1.5;
}
