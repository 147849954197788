.empty {
  // margin: 10rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3rem 0;

  &__link,
  &__link:visited {
    color: $white;
  }
  &__content {
    margin: 2rem 0;
    font-size: 1.5rem;
    .empty__text {
      font-size: 2rem;
      font-weight: 500;
    }
    .red__text {
      color: $red3;
    }
  }

  &__img {
    width: 20rem;
  }
}
