.home {
  margin: 0 5rem 5rem 5rem;
  @include responsive(750px) {
    margin-left: unset;
    margin-right: unset;
  }
}

.best-deal {
  font-size: 1.8rem;
  font-weight: 500;
  background-color: $primary;
  color: $white;
  border-radius: 0.8rem;
  padding: 1rem 2rem;
}

.details {
  &_part {
    background-color: $white;
    padding: 2.5rem 2.6rem;
    :not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &_title {
    font-size: 2rem;
    color: $ftBlackSec;
    font-weight: 600;
    border-bottom: 2px solid $grey2;
    padding-bottom: 11px;
    padding-left: 2.2rem;
  }

  &_text {
    font-size: 1.7rem;
    font-weight: 400;
    color: $black4;
    padding: 2rem 3rem 3rem 2.2rem;
  }
}

.key_features {
  margin-left: 1.8rem;
}

.features {
  font-size: 1.7rem;
  font-weight: 600;
  color: $black4;
  padding: 2.2rem 3rem 2rem 2rem;

  &_lists {
    padding-left: 4rem;
  }

  &_listed {
    font-size: 1.7rem;
    font-weight: 400;
    color: $black4;
    padding-left: 1rem;
    padding-bottom: 1.5rem;
    list-style: disc;
  }
}

.specs {
  margin-top: 3rem;
  padding-left: 2rem;

  &_second {
    font-size: 1.7rem;
    color: $black4;
    font-weight: 600;
    padding-bottom: 2.5rem;
  }

  &_info {
    font-size: 1.7rem;
    font-weight: 600;
    color: $ftBlackSec;
    padding-bottom: 1.5rem;
    // padding-left: 2.5rem;

    span {
      font-size: 1.8rem;
      font-weight: 400;
      columns: $ftBlackSec;
    }
  }
}
