.feedback {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  padding: 0.8rem;
  padding-right: 1.5rem;
  border-radius: 0.4rem;

  .message {
    font-size: 1.5rem;
    margin-left: 1rem;
    display: flex;
    flex: 1;
  }

  ion-icon {
    cursor: pointer;
    font-size: 2rem;
    // color: $white;
  }

  &__images {
    ion-icon {
      color: $red3;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
