*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

body {
  padding-top: 12.8rem;

  box-sizing: border-box;

  @media (max-width: 1150px) {
    padding-top: 8rem;
  }

  @media (max-width: 750px) {
    padding-top: 12.8rem;
  }
}

input {
  font-family: inherit;
  outline: none;
  background-color: transparent;
  border: none;
  min-width: 0;
}

button {
  font-family: inherit;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

a {
  &:link,
  &:visited {
    text-decoration: none;
    color: var(--text-color);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--login-input) !important;
  
}
