.limited {
  &-part {
    margin: 0 0;
  }

  &-banner {
    border-radius: 0.7rem;
    width: 100%;
  }
}
