.orderdetails {
  padding-right: 1rem;
  flex: 1;
  @include responsive(500px) {
    padding-right: 0;
  }

  &__info {
    padding: 2rem 0;

    .order__tracking__process {
      .btns {
        display: flex;
        flex-wrap: wrap;
        // justify-content: flex-end;
        justify-content: space-between;
        margin-bottom: 2rem;
        row-gap: 1rem;
        margin-top: 1rem;
        font-weight: 500;

        @include responsive(425px) {
          display: block;
        }

        .order__btns {
          color: $white;
          font-size: 1.2rem;
          padding: 0.6rem 1.5rem;
          border-radius: 0.4rem;
          text-transform: uppercase;
        }

        .cancel {
          background: $cancelled-left-border;
        }

        .tracking {
          background: $grey3;
          pointer-events: none;
          margin-right: 1rem;
        }

        .rate__order {
          background: $primary;
        }

        .rate__products {
          background: $white;
          color: $primary;
          border: 1px solid $primary;
          background: var(--rate-product);
        }

        .disableClick {
          pointer-events: none;
        }

        .enableClick {
          cursor: pointer;
        }

        .disable_rateOrder {
          background: #b4b4b4;
        }
        .rate__buttons {
          .rate__products {
            margin-right: 1rem;
          }
        }
      }

      .delay {
        &__popup {
          background-color: #6663dd;
          color: $white;
          width: 50%;
          padding: 10px 18px;
          border-radius: 4px;

          @include responsive(425px) {
            width: 100%;
            margin-bottom: 1.5rem;
          }
        }
        &__alert {
          display: flex;
          gap: 1rem;

          ion-icon {
            font-size: 1.8rem;
          }

          .text {
            font-size: 1.3rem;
          }
        }

        &__date {
          text-align: right;
          font-size: 1.2rem;
          margin-bottom: 2rem;
        }
      }

      .show__notes {
        visibility: visible;
      }
      .hide__notes {
        visibility: hidden;
      }
    }

    .order__information {
      .all__orders {
        background: $black;
        color: $white;
        padding: 1rem;
        width: 100%;
        border-radius: 0.8rem;
        margin: 0.8rem 0;
        background: var(--details-info);
      }

      .order__info {
        background: $white;
        padding: 2rem;
        border-radius: 0.8rem;
        background: var(--navtab-bg);

        .order__price {
          padding-top: 2rem;

          &__list {
            display: flex;
            flex-direction: column;
            background: $order-price-bkg;
            padding: 2rem;
            border-radius: 0.8rem;
            background: var(--details-total);

            &__title {
              margin-bottom: 0.5rem;
              display: flex;
              justify-content: space-between;

              .price {
                font-size: 1.5rem;
                font-weight: 600;
                margin-right: 2rem;

                @include responsive(425px) {
                  margin-right: 1rem;
                }
              }
              .coupon__fee__deduct {
                color: $green1;
              }
            }
          }

          .price__title {
            font-weight: 600;
            flex: 5;
          }

          .order__price__total {
            padding: 2rem;
            display: flex;
            justify-content: space-between;

            .price__total {
              font-size: 1.8rem;
              font-weight: 700;
              margin-right: 2rem;

              @include responsive(425px) {
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

// order list style

.order__items {
  &__details {
    font-weight: 600;
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include responsive(1120px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include responsive(500px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.order__list {
  width: 100%;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-start;
  padding-bottom: 1rem;
  border-bottom: 1px solid $order-bottom-border;
  border-bottom: 1px solid var(--checkout-border);

  &__img {
    margin-right: 1rem;
  }

  &__detail {
    flex-direction: column;

    .order__name__price {
      .price {
        display: block;
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }
  }
}

// order card styles
.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 3rem;
  @include responsive(1119px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include responsive(930px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include responsive(769px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include responsive(360px) {
    grid-template-columns: 1fr;
  }

  .orderCard {
    padding: 2.5rem 1.5rem;
    flex: 1;
    border-radius: 0.8rem;

    &__name {
      display: flex;
      align-items: center;

      span {
        font-size: 1.4rem;
        margin-left: 1rem;
        @include responsive(1240px) {
          font-size: 1.3rem;
        }
      }
    }

    &__val {
      margin-top: 0.5rem;
      color: $black;
      font-size: 1.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      color: var(--details-text);
      .delivery__method {
        font-size: 12px;
        background: white;
        padding: 4px;
        color: black;
        border-radius: 4px;
        margin-left: 4px;
      }
      @include responsive(1240px) {
        font-size: 1.3rem;
      }
    }
  }

  .orange {
    background: $processing-bkg;
    color: $processing-left-border;
    background: var(--processing-bg);
  }

  .grey {
    color: $orderdetails-card-text;
    background: $delivery-order;
    background: var(--details-grey);
  }

  .green {
    background: $delivered-bkg;
    color: #397305;
    background: var(--details-green);
    color: var(--green-text);
  }

  .blue {
    color: $orderdetails-card-text;
    background: $shipped-bkg;
    background: var(--details-blue);
  }
}

.order__location {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  font-size: 1.5rem;
  @include responsive(530px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include responsive(425px) {
    grid-template-columns: 1fr;
  }
  @include responsive(1024px) {
    gap: 2rem;
  }

  .bold,
  .mail {
    display: block;
    padding: 0.5rem 0;
    font-weight: 600;
  }

  .mail {
    margin-top: 2rem;
    @include responsive(1024px) {
      margin-top: 1rem;
    }
  }

  .location__head {
    display: flex;
    align-items: center;

    ion-icon {
      margin-right: 0.5rem;
    }
  }
}

// container for product images
.prodImg__container {
  padding: 1rem;
  background: $order-price-bkg;
  border-radius: 0.8rem;
  width: 9rem;
  height: 9rem;

  img {
    width: 7rem;
    height: 7rem;
    object-fit: cover;
  }
}
