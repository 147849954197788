.orders {
  position: relative;
  height: 100%;
  padding-right: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  @include responsive(500px) {
    padding-right: 0;
  }

  .fixed__head {
    position: sticky;
    top: 0;
    padding-bottom: 1px;
    background: #efefef;
    background-color: var(--order-bg);
    // overflow-x: scroll;
    @include responsive(500px) {
      top: 0;
      padding-top: 0.48rem; //0.5rem
    }

    .orders__tabs {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;
      padding-top: 2rem;
      font-size: 1.4rem;
      overflow-x: scroll;
      border-bottom: 1px solid #dddddd;
      border-bottom: 1px solid var(--order-border);
      // width: 100%;
      @include responsive(500px) {
        // display: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .tab {
        cursor: pointer;
        display: flex;
        padding-bottom: 0.5rem;
        min-width: fit-content;
        align-items: center;

        img {
          margin-right: 0.5rem;
        }
      }

      .active {
        border-bottom: 2px solid $green1;
      }
    }

    .orders__search {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: 1.4rem;
      @include responsive(440px) {
        display: block;
      }

      .search__order {
        width: 20rem;
        background: $white;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 0.4rem;
        @include responsive(440px) {
          width: 100%;
          margin-bottom: 1rem;
        }

        input {
          width: 100%;
          margin-left: 1rem;
        }
      }

      .filter__order {
        margin-left: 2rem;
        display: flex;
        align-items: center;
        @include responsive(440px) {
          margin-left: 0;
        }

        select {
          padding: 0.5rem 1rem;
          border-radius: 0.4rem;
          margin-left: 1rem;
          background-color: var(--pay-bg);
        }
      }
    }
  }

  &__content {
    margin-top: 1rem;
  }
}
