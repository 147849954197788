.ranking {
  background-color: #ffffff;
  border-radius: 0.4rem;
  padding: 3rem;
  margin-top: 2rem;
  background-color: var(--navtab-bg);

  @include responsive(375px){
    padding: 2rem;
  }

  &__body {
    gap: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2rem 0.8rem;
  }

  &__grades {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      font-size: 1.4rem;
      color: #555555;
      padding-top: 0.3rem;
      color: var(--account-text);
    }

    .ranks {
      font-size: 1.2rem;
      color: #b0b0b0;
      padding-top: 0.5rem;
      color: var(--rank-fig);
    }
  }

  &__benefits {
    margin-top: 0.4rem;

    .benefitt {
      font-size: 1.6rem;
      font-weight: 700;
      color: #3b7f04;
    }
  }

  &__list {
    display: flex;
    gap: 2rem;
    line-height: 2.7;

    .listed {
      flex: 2;
      @include responsive(1230px) {
        flex: 3;
      }
      @include responsive(1230px) {
        flex: 4;
      }
      @include responsive(530px) {
        flex: 5;
      }
      @include responsive(465px) {
        flex: 6;
      }
      @include responsive(395px) {
        flex: 7;
      }
      @include responsive(335px) {
        flex: 10;
      }
      &__ranks {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .text {
          font-size: 1.6rem;
          font-weight: 500;
          color: #555555;
          color: var(--rank-text);

          @include responsive(320px) {
            font-size: 1.4rem;
          }
        }
      }

      &__info {
        flex: 10;
        .infos {
          font-size: 1.6rem;
          font-weight: 500;
          color: #b0b0b0;
          color: var(--rank-info);
        }
      }
    }
  }
}
