.rate__products {
  margin-right: 1rem;
  flex: 1;
  @include responsive(500px) {
    margin-right: 0;
  }

  .all__products {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    @include responsive(1120px) {
      flex-direction: column;
    }

    .product__item {
      height: max-content;
      cursor: pointer;
    }

    .product__item,
    .rate__details {
      padding: 1.5rem 2rem;
      background: $white;
      border-radius: 0.8rem;
      background: var(--navtab-bg);

      .item__rate {
        display: flex;
        gap: 1rem;

        .rate__img {
          padding: 1rem 0.8rem;
          background: $order-price-bkg;
          border-radius: 0.8rem;
          width: 8rem;
          height: 8rem;
        }

        .product__rate {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          justify-content: flex-start;

          &__price {
            font-weight: 600;
            color: $black;
            color: var(--details-text);
          }

          &__quantity {
            margin-top: 1rem;
            font-size: 1.2rem;

            .qty {
              color: $black;
            }
          }
        }
      }
    }

    .products__list {
      color: $default-address;
      font-size: 1.4rem;
      flex: 4;
      gap: 1rem;
      height: fit-content;
      display: grid;
      grid-template-columns: 1fr;
      @include responsive(1120px) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3rem;
      }
      @include responsive(930px) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 1rem;
      }
      @include responsive(845px) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
      }
      @include responsive(500px) {
        grid-template-columns: 1fr;
      }

      .activeProduct {
        border: 1px solid $sidebar-user-bkg;
        border-left-width: 0.3rem;
      }
    }

    .rate__details {
      flex: 6;
      padding: 3rem;
      border-radius: 0.8rem;
      @include responsive(1120px) {
        margin-top: 2rem;
      }

      .item__rate {
        font-size: 1.4rem;
      }
    }
  }
}

.leave__review {
  width: 100%;
  &__title {
    border-radius: 0.4rem;
    background: $black;
    color: $white;
    text-align: center;
    padding: 1rem 0;
    margin: 1rem 0;
  }

  .rating__order {
    display: flex;
    height: fit-content;
    label {
      margin-bottom: 0;
    }
    .radio__rating {
      display: none;
    }
  }

  label {
    display: block;
    margin: 1rem 0;
  }

  .input__fields {
    width: 100%;

    input,
    textarea {
      width: 100%;
    }

    textarea {
      resize: none;
    }
  }

  .input__ratio {
    display: block;
  }

  .input__radio {
    display: flex;
    gap: 1rem;
    label {
      margin: 0;
    }
  }

  .submit__rate__product {
    background: $lemon;
    color: $white;
    width: 40%;
    padding: 1rem 0;
    border-radius: 0.4rem;
    margin-top: 2rem;
  }
}

// style for star rating
.star {
  font-size: 30px;
  cursor: pointer;
  margin-right: 0.5rem;
  color: $ratingsYellow;
}
.yellow {
  color: $ratingsYellow;
}
.grey {
  color: $grey3;
}
