.show__popup__login {
  background-color: rgba($black, 0.4);
  width: 100%;
  height: 100vh;
  z-index: 2000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;

  .log__popup {
    position: fixed;
    right: 0%;
    top: 0%;
    overflow-y: auto;
    transition: all 0.3s;
    height: 100%;
    overflow-y: auto;
    transform: translate(100%);

    // @include responsive(1496px) {
    //   top: 13%;
    // }
    // @include responsive(1260px) {
    //   top: 15.5%;
    // }
    // @include responsive(1150px) {
    //   top: 9.5%;
    // }
    // @include responsive(801px) {
    //   top: 11%;
    // }
    // @include responsive(750px) {
    //   top: 21.5%;
    // }
  }

  .show__auth__login {
    transform: translateX(0);
  }

  .close__pop {
    font-size: 2rem;
    cursor: pointer;
    padding-left: 25px;
  }

  .auth__main {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    @include responsive(550px) {
      border-radius: 1.2rem !important;
      width: 100%;
      top: 15%;
      height: calc(100% - 15%);
    }
  }
  .red__link__auth {
    color: $red1;
    cursor: pointer;
  }
}
