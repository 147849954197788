.items__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5rem;
  @include responsive(1150px) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
  }
  @include responsive(1110px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
  }
  @include responsive(930px) {
    column-gap: 2rem;
  }
  @include responsive(530px) {
    grid-template-columns: 1fr;
    column-gap: 0px;
    margin-top: 1rem;
  }

  .ordered__item {
    padding: 1rem 3rem;
    border-radius: 0.8rem;
    margin-bottom: 1.5rem;
    display: flex;
    font-weight: 500;
    font-size: 1.4rem;
    column-gap: 1rem;
    // justify-content: space-between;
    min-height: 15rem;
    @include responsive(1160px) {
      font-size: 1.3rem;
    }

    .order__headline {
      color: #808080;
      margin-right: 0.5rem;
      color: var(--order-text);
    }

    .order__detail {
      display: flex;
      align-items: center;
      &:last-child {
        margin-left: 0.5rem;
      }

      &__number {
        margin: 0.3rem 0;
      }

      .order__in__delivery {
        width: fit-content;
        padding: 0.3rem 1rem;
        color: $white;
        font-size: 1.2rem;
        border-radius: 0.4rem;
        text-align: center;
      }

      .in__delivery {
        background-color: $progress-yellow;
      }

      .cancelled {
        background-color: $grey8;
      }

      .delivered {
        background-color: $lemon;
      }

      .confirmed {
        background-color: $confirmed-order;
      }
    }
    .status__order {
      margin-left: 0rem !important;
    }

    .left {
      display: none;
      @include responsive(1150px) {
        display: flex;
        margin-left: 0;
        margin-top: 0.5rem;
      }
      @include responsive(930px) {
        display: none;
      }
      @include responsive(767px) {
        display: flex;
      }
      @include responsive(530px) {
        display: none;
      }
      // @include responsive(360px) {
      //   display: flex;
      // }
    }

    &__info {
      flex: 1;
      cursor: pointer;
    }
    &__tracking {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      text-align: right;
      // visibility: hidden;
      @include responsive(1150px) {
        display: none;
      }
      @include responsive(930px) {
        display: flex;
      }
      @include responsive(767px) {
        display: none;
      }
      @include responsive(530px) {
        display: flex;
      }
      // @include responsive(360px) {
      //   display: none;
      // }
    }
  }

  // style for loader
  .loading {
    padding: 1rem 3rem;
    border-radius: 0.8rem;
    margin-bottom: 1.5rem;
    display: flex;
    font-weight: 500;
    font-size: 1.4rem;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
  }

  .chat__link {
    width: fit-content !important;
    margin-left: auto;
    .chat__with__us {
      background: $white;
      padding: 0.5rem 1rem;
      border-radius: 0.4rem;
      margin-left: 0;
      width: fit-content;
      margin-left: auto;
      background: var(--header-bg);
      color: #7bb20e;

      .margin__left__chat {
        margin-left: 0.5rem;
      }
    }
  }

  .processing {
    background: $processing-bkg;
    border-left: 0.5rem solid $processing-left-border;
    background: var(--processing-bg);
  }

  .pending {
    background: $pending-bkg;
    border-left: 0.5rem solid $pending-left-border;
    background: var(--pending-bg);
  }

  .shipped {
    background: $shipped-bkg;
    border-left: 0.5rem solid $shipped-left-border;
    background: var(--shipped-bg);
  }

  .cancelled {
    background: $cancelled-bkg;
    border-left: 0.5rem solid $cancelled-left-border;
    background: var(--cancelled-bg);
  }

  .delivered {
    background: $delivered-bkg;
    border-left: 0.5rem solid $delivered-left-border;
    background: var(--details-green);
  }
}

.loading:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    110deg,
    rgba(227, 227, 227, 0) 0%,
    rgba(227, 227, 227, 0) 40%,
    rgba(227, 227, 227, 0.5) 50%,
    rgba(227, 227, 227, 0) 60%,
    rgba(227, 227, 227, 0) 100%
  );
  animation: gradient-animation_2 1.2s linear infinite;
}
