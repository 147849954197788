.home-categories {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  @media (max-width: 1400px) {
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: 550px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 350px) {
  }

  &__category {
    flex: 1;

    border-radius: 0.8rem;
    padding: 1.8rem;
    background-color: var(--navtab-bg);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.8rem;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: #4a9f05;
    }

    @media (max-width: 1400px) {
      flex: initial;
      flex-shrink: 0;
      padding: 1.8rem 3.6rem;
    }

    @media (max-width: 550px) {
      padding: 1.8rem;
    }
  }
}

.best-brands {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__heading {
    font-size: 1.8rem;
  }

  @keyframes slide-brands {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-111.5%);
    }
  }

  &__logos {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
  }

  &__logo-slide:hover {
    animation-play-state: paused;
  }

  &__logo-slide {
    display: flex;

    animation: 35s slide-brands infinite linear;
  }

  &__logos:hover {
    animation-play-state: paused;
  }

  &__logo-slide img {
    width: 25rem;
    height: 14rem;
    border-radius: 0.8rem;
    margin: 0 1rem;
    cursor: pointer;
    object-fit: cover;

    @media (max-width: 728px) {
      width: 20rem;
      height: auto;
    }
    @media (max-width: 600px) {
      width: 15rem;
      height: auto;
    }
  }
}

.popular-categories {
  display: flex;
  justify-content: center;

  &__container {
    max-width: 130rem;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    min-width: 0;

    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  &__header {
    font-size: 1.8rem;
    color: #3b7f04;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .see__all__cat {
      color: #7bb20e;
      font-size: 1.4rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__categories {
    display: flex;
    align-items: center;
    gap: 3.6rem;

    @media (max-width: 1000px) {
      gap: 2.4rem;
    }

    @media (max-width: 900px) {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__category {
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.8rem;

    cursor: pointer;
  }

  &__category-img {
    display: block;
    width: 18rem;
    height: 18rem;

    @media (max-width: 1350px) {
      width: 12rem;
      height: 12rem;
    }
  }

  &__category-name {
    font-size: 1.6rem;
    font-weight: 600;
  }
}

.reach-section {
  padding: 5.6rem 0;

  background-repeat: no-repeat;
  background-size: cover;

  &__header {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 3.6rem;
    text-align: center;

    & span {
      color: #3b7f04;
    }
  }

  &__section {
    display: grid;
    grid-template-columns: 20fr 20fr 20fr 40fr;
    gap: 4.8rem;

    @media (max-width: 1050px) {
      gap: 2.4rem;
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 750px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__section-block {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__contact {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  &__contact-main {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__contact-img {
    display: block;
    width: 3.6rem;
    height: 3.6rem;
  }

  &__contact-name {
    font-weight: 600;
    color: #3b7f04;
  }
}

.reach-section__bkg__light {
  background-image: url(/src/assets/imgs/reach-bg.svg);

  @media (max-width: 768px) {
    background-image: url(/src/assets/imgs/callcenterbg.png);
  }
}
.reach-section__bkg__dark {
  background-image: url(/src/assets/imgs/reach-bg-dark.svg);

  @media (max-width: 768px) {
    background-image: url(/src/assets/imgs/callcenterbgdark.png);
  }
}
